































































import { Component, Vue } from 'vue-property-decorator';
import AddSpecRequestForm from '@/components/partials/profile/AddSpecRequestForm.vue';
import TheArrowHorizIcon from '@/components/icons/TheArrowHorizIcon.vue';
import TheInstagramIcon from '@/components/icons/TheInstagramIcon.vue';

interface IContact {
  id: string;
  region: string;
  addressLine1: string;
  addressLine2: string;
  country: string;
  phone: string;
  link: string;
}

@Component({
  components: { TheInstagramIcon, TheArrowHorizIcon, AddSpecRequestForm },
  metaInfo: {
    title: 'Contact us',
  },
})
export default class ContactPage extends Vue {
  contacts: IContact[] = [
    {
      id: 'contact_europe',
      region: 'EUROPE',
      addressLine1: '61 Tottenham Court Road',
      addressLine2: 'London, W1T 2EP',
      country: 'United Kingdom',
      phone: '+44 [0] 203 384 0055',
      link: 'https://www.instagram.com/thecultivisteurope',
    },
    {
      id: 'contact_america',
      region: 'AMERICAS',
      addressLine1: '55 Fifth Avenue, Suite 1305',
      addressLine2: 'New York, NY 10003',
      country: 'United States',
      phone: '+1 212 257 0202',
      link: 'https://www.instagram.com/thecultivistamericas',
    },
  ];
}
