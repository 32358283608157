<template>
  <form class="comment-form profile-form add-request-form" @submit.prevent="onSubmit">
    <div v-if="addingNewRequest" class="add-request-form__loader-wrapper">
      <AppLoader class="add-request-form__loader" />
    </div>

    <textarea
      v-model.trim="text"
      class="form-control"
      maxlength="254"
      placeholder="Start typing..."
      @input="handleInputText"
    />
    <p v-if="error" class="error-msg text-danger">{{ error }}</p>

    <div v-if="text" class="comment-form__btns">
      <button class="btn profile-form__clean" @click="cleanInputText">
        <TheCrossIcon />
      </button>
    </div>

    <button class="btn border-btn profile-form__send" type="submit">Send</button>
  </form>
</template>

<script>
import specialRequestMutation from '@/graphql/me/MakeSpecialRequest.mutation.gql';

import TheCrossIcon from '@/components/icons/TheCrossIcon.vue';

export default {
  name: 'AddSpecRequestForm',
  components: { TheCrossIcon },
  props: {},
  data() {
    return {
      addingNewRequest: false,
      text: '',
      error: '',
    };
  },
  methods: {
    cleanInputText() {
      this.text = '';
    },
    handleInputText() {
      this.error = '';
    },
    onSubmit() {
      if (!this.text) {
        this.error = 'The text field is required.';
        return;
      }

      this.addingNewRequest = true;
      this.$apollo
        .mutate({
          mutation: specialRequestMutation,
          variables: {
            spec_request_text: this.text,
          },
        })
        .then(({ data = { SpecialRequestMutation: null } }) => {
          this.addingNewRequest = false;
          if (data.SpecialRequestMutation) {
            this.text = '';
            this.$toast.success('Our team will get back to you shortly.');
            this.$emit('added', data.SpecialRequestMutation);
          }
        })
        .catch(() => {
          this.addingNewRequest = false;
          this.$emit('error');
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.add-request-form {
  position: relative;

  &__loader {
    &-wrapper {
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgba(255, 255, 255, 0.5);
    }

    position: relative;
    top: -70px;
  }
}
.error-msg {
  margin-top: 10px;
  font-size: 16px;
}
</style>
