<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <defs>
      <path
        id="21dja"
        d="M162 297c-3.26 0-3.667.014-4.948.072-1.95.09-3.663.567-5.038 1.942-1.375 1.375-1.853 3.087-1.942 5.038-.058 1.28-.072 1.689-.072 4.948 0 3.26.014 3.667.072 4.948.09 1.95.567 3.663 1.942 5.038 1.375 1.375 3.088 1.853 5.039 1.942 1.28.058 1.688.072 4.947.072 3.26 0 3.667-.014 4.948-.072 1.95-.09 3.663-.567 5.038-1.942 1.375-1.375 1.853-3.088 1.942-5.039.058-1.28.072-1.688.072-4.947 0-3.26-.014-3.667-.072-4.948-.09-1.95-.567-3.663-1.942-5.038-1.375-1.375-3.088-1.853-5.039-1.942-1.28-.058-1.688-.072-4.947-.072zm0 2.162c3.204 0 3.583.013 4.85.07 1.365.063 2.632.336 3.607 1.311.975.975 1.248 2.242 1.311 3.608.058 1.265.07 1.645.07 4.85 0 3.204-.012 3.583-.07 4.849-.062 1.365-.336 2.632-1.311 3.607-.975.975-2.243 1.249-3.608 1.311-1.265.058-1.645.07-4.849.07s-3.584-.012-4.85-.07c-1.365-.062-2.632-.335-3.607-1.31-.975-.976-1.248-2.243-1.311-3.609-.058-1.265-.07-1.645-.07-4.849s.013-3.583.07-4.85c.063-1.365.336-2.632 1.311-3.607.975-.975 2.243-1.248 3.608-1.311 1.265-.058 1.645-.07 4.849-.07z"
      />
      <path
        id="21djb"
        d="M162.002 313.002a4 4 0 1 1 0-8 4 4 0 0 1 0 8zm0-10.162a6.162 6.162 0 1 0 0 12.324 6.162 6.162 0 0 0 0-12.324z"
      />
      <path id="21djc" d="M166.97 302.59a1.44 1.44 0 1 1 2.88 0 1.44 1.44 0 0 1-2.88 0z" />
    </defs>
    <g>
      <g transform="translate(-150 -297)">
        <g><use fill="#3c3c3c" xlink:href="#21dja" /></g>
        <g><use fill="#3c3c3c" xlink:href="#21djb" /></g>
        <g><use fill="#3c3c3c" xlink:href="#21djc" /></g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'TheInstagramIcon',
};
</script>
